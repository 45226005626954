@use "utilities/utils" as u;

.share-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid black;
  margin: auto;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  margin-bottom: 2rem;
}