@use "utilities/utils" as u;

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  max-width: min(95vw, 80rem);
  & h1, h2 {
    margin: 1rem 0.5rem 0.25rem 0.5rem;
  }
  & p {
    margin: 0rem 0.5rem 1rem 0.5rem;
  }
  & img {
    max-width: 95vw;
    margin: auto;
    padding: 1rem 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  & a {
    color: u.$diarrhea !important;
  }
}