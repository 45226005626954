@use "utilities/utils" as u;

button.start-pause {
  margin: auto;
  padding: 0.5rem;
  display: flex;
  width: 3rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
}