@use "utilities/utils" as u;

.option-container {
  display: flex;
  flex-direction: column;
  width: 12rem;
  & > label {
    font-size: 0.875rem;
    text-align: left;
    padding-left: .5rem;
  }
  & > input, select {
    box-sizing: border-box;
    margin: 0.25rem 0.5rem 0.75rem 0.5rem;
    border: 2px solid black;
    max-height: 2rem;
  }
  .teal-shadow {
    box-shadow: -0.25rem 0.25rem u.$teal;
  }
  & .velvet-shadow {
    box-shadow: -0.25rem 0.25rem u.$velvet;
  }
  & .purple-shadow {
    box-shadow: -0.25rem 0.25rem u.$purple;
  }
}