@use "utilities/utils" as u;

.settings-list {
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 14rem);
}

.settings-list-container {
  display: flex;
  flex-direction: row;
}