@use "utilities/utils" as u;

// Media queries
@media (max-width: 640px) {
  .campanula-logo {
    display: none;
  }
  .main-lines {
    padding-left: 0 !important;
  }
  .main-headline {
    text-align: center;
  }
  .main-subheadline {
    text-align: center;
  }
  .main-header {
    font-size: 1.75rem !important;
  }
  h1.pomos-header {
    font-size: 2.25rem;
  }
}

@media (min-width: 640px) {
  .campanula-logo-mobile {
    display: none;
  }
}

// Default styles

#root {
  font-family: "Readex Pro", "system-ui";
  color: #171717;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  gap: 2rem;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  // position: sticky;
  // transition: u.$defTransition;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  
  // & > section {
  //   max-width: min(90rem, 100%);
  //   padding: 1rem;
  // }
}

footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: u.$black;
  color: u.$cream;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  & a {
    color: u.$lightDiarrhea !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Fraunces", serif;
  font-weight: 400;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2.main-header {
  font-weight: 700;
  font-size: 2.5rem; 
}

a, a:link, a:visited, a:hover, a:active {
  color: inherit;
}

section {
  // padding: 1rem;
}

input {
  border: solid u.$black 2px;
}

// Utilities

.short-hr {
  color: u.$diarrhea;
  width: min(2rem, 95vw);
  border-top: u.$diarrhea 0.25rem solid;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.long-hr {
  color: u.$diarrhea;
  width: min(42rem, 95vw);
  border-top: u.$diarrhea 0.25rem solid;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cream {
  color: u.$cream;
}

.teal {
  color: u.$teal;
}

.purple {
  color: u.$purple;
}

.velvet {
  color: u.$velvet;
}

.black {
  color: u.$black;
}

.diarrhea {
  color: u.$diarrhea;
}

.monospace {
  font-family: "Azeret Mono", monospace !important;
}

.bgteal {
  background-color: u.$teal;
}

.bgpurple {
  background-color: u.$purple;
}

.bgvelvet {
  background-color: u.$velvet;
}

.full-width-svg {
  width: 100%;
}

.styled-a {
  color: u.$diarrhea !important;
  text-decoration: underline;
}

.button {
  color: u.$black !important;
  border: solid 2px u.$black;
  line-height: 1.75rem;
  font-weight: 600;
  padding: 0.5rem;
  text-transform: uppercase;
  // font-family: "Azeret Mono", monospace;
  transition: u.$defTransition;
  background-color: u.$cream;
  box-shadow: -0.25rem 0.25rem u.$teal;
  &:hover {
    box-shadow: -0.5rem 0.375rem u.$teal;
  }
}

.button-alt {
  color: u.$cream !important;
  border: solid 2px u.$cream;
  line-height: 1.75rem;
  min-width: 1.75rem;
  text-align: center;
  font-weight: 600;
  padding: 0.5rem;
  text-transform: uppercase;
  // font-family: "Azeret Mono", monospace;
  transition: u.$defTransition;
  background-color: u.$purple;
  box-shadow: -0.25rem 0.25rem u.$purple;
  &:hover {
    box-shadow: -0.5rem 0.375rem u.$purple;
  }
}

.scale-object {
  width: min(80rem, 95%);
  justify-self: center;
}

// Font utils

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

@function to-kebab-case($string) {
  @return to-lower-case(str-replace($string, ' ', '-'))
}

@each $font in u.$fonts {
  .font-#{to-kebab-case($font)} {
    font-family: $font;
  }
}