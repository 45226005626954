@use "utilities/utils" as u;

.pomos-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .pomos-list-desc {
    text-align: center;
  }
  padding: 2rem;
}

.template-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 1rem;
  width: 100%;
  max-width: 80rem;
}

.pomo-template {
  max-width: min(30rem, 90vw);
  width: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  box-shadow: -0.5rem 0.5rem u.$black;
  padding: 1rem;
  transition: u.$defTransition;
  & h2 {
    margin: 0.5rem 0;
  }
  &:hover {
    box-shadow: -1rem 0.75rem u.$black;
  }
}