@use "utilities/utils" as u;

.main-top {
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-self: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-image: linear-gradient(to right, rgba(255,255,255,30) 30%, rgba(255, 255, 255, 0));
  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

.main-lines {
  width: min(95vw, 80rem);
  padding-left: 1rem;
}

.main-headline {
  font-size: min(7rem, 12vw);
  font-weight: 400;
  max-width: 60rem;
}

.main-subheadline {
  font-size: min(3.5rem, 6vw);
  font-weight: 100;
}

@keyframes tomatoFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-tomato {
  justify-self: end;
  max-height: 50rem;
  height: 100%;
  max-width: 40rem;
  width: 100vw;
  z-index: -3;
  right: 0;
  opacity: 0;
  animation: tomatoFade ease 2s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.gradient-customizable {
  font-weight: 600;
  font-family: "Fraunces", serif;
  font-style: italic;
  background-image: linear-gradient(90deg, u.$teal, u.$purple, u.$velvet);
  background-clip: text;
  background-size: 100%;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.description {
  // max-width: 80rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  // gap: 2rem;
  & .subcontainer {
    width: min(80rem, 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.revolutionize {
  & > .subcontainer {
    column-gap: 2rem;
    & > .desc-text-revolutionize {
      color: white !important;
      flex: 1 1 25rem;
      min-height: 20rem;
      border-radius: 4rem 4rem 4rem 0;
      width: min(53rem, 100vw);
      background-color: u.$teal;
      color: u.$cream;
      padding: 1rem;
      box-sizing: border-box;
    }
    & > .desc-image-revolutionize {
      // max-width: 25rem;
      // max-height: 25rem;
      width: min(25rem, 95vw);
      height: auto;
    }
  }
}

.desc-customize-container {
  & > .subcontainer {
    // column-gap: 12rem;
    align-items: center;
    & > .desc-image-customize-container {
      background-color: u.$teal;
      width: min(25rem, 95vw);
      height: auto;
      border-radius: 0 10rem 4rem 10rem;
      box-sizing: border-box;
      padding: 1rem;
      & > img {
        width: 100%;
        height: auto;
      }
    }
    & > .desc-text-customize {
      flex: 1 1 25rem;
      max-width: min(41rem, 100vw);
      padding: 0 min(15vw, 7rem);
    }
  }
}

.any-device {
  column-gap: 2rem;
  & .subcontainer {
    & > .desc-text-any-device {
      flex: 1 1 25rem;
      align-self: center;
      border-radius: 4rem;
      width: min(48rem, 95vw);
      box-sizing: border-box;
      padding: 1rem;
      margin-bottom: 2rem;
    }
    & > .desc-text-any-device-image {
      width: min(30rem, 95vw);
      // height: min(28rem, 95vw);
      align-self: center;
    }
  }
}

.desc-intuitive {
  width: 100%;
  align-items: inherit;
  justify-content: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  & > img {
    width: 100%;
    height: auto;
  }
  & > .intuitive {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: u.$lightVelvet;
    & > .subcontainer {
      padding: 2rem 0;
      & > .desc-text-intuitive {
        flex: 1 1 25rem;
        max-width: min(48rem, 95vw);
        color: u.$cream;
        box-sizing: border-box;
      }
      & > .desc-text-intuitive-image {
        width: min(30rem, 95vw);
        align-self: center;
      }
    }
  }
}

.desc-template {
  width: 100%;
  & > .desc-text-template {
    background-color: #522B47;
    border-radius: 4rem 4rem 0 0;
    color: u.$cream;
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
  }
  & > .desc-text-template-image {
    width: min(35rem, 90vw);
    margin: auto;
  }
}

.desc-text-template-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  gap: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}