section.main-404 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 1rem 0;
  max-width: 95vw;
  align-self: center;
  text-align: center;
}

h1.h1-404 {
  font-size: 6rem;
  font-weight: 700;
  margin: .5rem 0;
}

h2.h2-404 {
  font-size: 2rem;
  margin: 0 0 1.5rem 0;
}

img.not-found-svg {
  max-width: min(40rem, 95vw);
}