// Variables

$black: #1c1c20;
$cream: #FBF5F3;
$teal: #8DAA9D;
$purple: #522B47;
$velvet: #7B0828;
$lightVelvet: #55031a;
$diarrhea: #A98F33;
$lightDiarrhea: #cfb96f;

$defTransitionColor: 0.5s ease color;
$defTransition: 0.5s ease box-shadow;

$fonts:
  'Readex Pro',
  'Manrope',
  'Open Sans',
  'Lato',
  'Overpass',
  'Source Sans Pro',
  'DM Sans',
  'Arial',
  'Tahoma',
  'Trebuchet MS',
  'Verdana' ,
  'system-ui',
  'Fraunces',
  'DM Serif Display',
  'Josefin Slab',
  'Times New Roman',
  'Georgia',
  'Lucida Bright',
  'Azeret Mono',
  'Space Mono',
  'Courier New',
  'Lucida Sans Typewriter';